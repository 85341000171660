<template>
   <div class="main">
        <div class="kefan">
            <div class="search">
                <el-input placeholder="请输入物料关键字" v-model="value">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <el-button type="primary" @click="searchtitle" plain>搜索</el-button>
                <el-button type="success" plain @click="dialogVisible = true">新增</el-button>
            </div>

            <!-- 表格 -->
            <div class="table" v-loading="loading">
                <el-table
                :data="tableData"
                border
                style="width: 90%;margin:10px auto;">
                <el-table-column
                    prop="id"
                    label="物料ID"
                    align="center"
                    width="100px">
                </el-table-column>

                <el-table-column
                    prop="boom_name"
                    label="物料名称"
                    align="center"
                    width="300px">
                </el-table-column>

                <el-table-column
                    prop="num"
                    label="数量"
                    align="center"
                    width="200px">
                </el-table-column>

                <el-table-column
                    prop="unit"
                    label="单位"
                    align="center"
                    width="100px">
                </el-table-column>

                <el-table-column
                    prop="date"
                    label="更新时间"
                    align="center"
                    width="300px">
                </el-table-column>

                <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="100px">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="large">编辑库存</el-button>
                </template>
                </el-table-column>
                
                </el-table>
               
            </div>

            <div class="page">
                <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                layout="prev, pager, next"
                :total="total">
                </el-pagination>
            </div>

            <el-dialog
            title="编辑库存"
            :visible.sync="dialogVisible"
            width="30%"
            v-loading="loading"
            >
            <div class="number">
                <el-input-number v-model="item_num" :precision="2" :step="0.1"></el-input-number>
                <p>{{item_unit}}</p>
            </div>
            
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmnum" style="width: 30%;">确 定</el-button>
            </span>
            </el-dialog>
        </div>
   </div>
</template>
<script>
import axios from 'axios';

export default {
   data() {
     return {
       value:"",//搜索关键字
       value1: '',//日期
       loading: false,//加载
       tableData: [],//表格数据
       total:0,//总条数
       page:1,//页码
       dialogVisible: false,//弹窗
       item_num:0,//数量
       item_unit:"",//单位
       item_id:0,//物料id
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     this.loaddata()
   },
   methods: {
    // loaddata 是一个方法，用于从服务器加载数据。
    // 首先，它检查 this.loading 是否为真。如果为真，那么方法直接返回，不执行任何操作。
    // 如果 this.loading 为假，那么方法将其设置为真，然后开始加载数据。
    // 它使用 axios 发送一个 GET 请求到服务器，请求的 URL 是由多个部分组成的。
    // 当请求成功返回后，它将 this.loading 设置为假，然后将返回的数据保存到 this.tableData，并将返回的总数保存到 this.total。
    loaddata(){
        if(this.loading){
            return;
        }
        this.loading = true;
        axios({
            method: 'get',
            url:this.$yz + this.$api.simple+"?mode=getlaststock&page="+this.page+"&value="+this.value,
        }).then((res)=>{
            this.loading = false;
            this.tableData = res.data.data;
            this.total = res.data.total;
        })
    },
    handleClick(s) {
        this.dialogVisible = true;
        this.item_num = s.num;
        this.item_unit = s.unit;
        this.item_id = s.id;
    },
    // handleCurrentChange 是一个方法，当页面发生变化时被调用。
    // 它接收一个参数 val，这个参数代表了当前的页面。
    // 方法首先将 this.page 的值设置为 val，然后调用 this.loaddata() 方法来加载新页面的数据。
    handleCurrentChange(val) {
        this.page = val;
        this.loaddata()
    },
    searchtitle(){
        this.page = 1;
        this.loaddata()
    },
    confirmnum(){
        if(this.loading){
            return;
        }
        this.loading = true;
        axios({
            method: 'get',
            url:this.$yz + this.$api.simple+"?mode=updatestock&num="+this.item_num+"&id="+this.item_id,
        }).then(()=>{
            this.loading = false;
            this.dialogVisible = false;
            this.loaddata()
        })
    }
   }
};
</script>
<style scoped>
.main {
    width: 100%;
}
.search{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.page{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.number{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.dialog-footer{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>